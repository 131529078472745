var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',[_c('v-card',{staticClass:"pa-4"},[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v("School admin - Licencias")])])],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-tabs',{attrs:{"light":""},on:{"change":_vm.onTabChange},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',[_vm._v("PK-2 Licenses")]),_c('v-tab',[_vm._v("3-5 Licenses")])],1)],1)],1),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',[_c('v-row',[_c('v-col',[_c('v-card',{staticClass:"p-0"},[_c('v-row',[_c('v-col',{attrs:{"cols":"8"}}),_c('v-col',{attrs:{"cols":"4"}},[_c('v-row',[_c('v-col',{staticClass:"font-weight-bold",staticStyle:{"background-color":"#a1c8fe"},attrs:{"cols":"8"}},[_c('label',[_vm._v("School licenses: ")])]),_c('v-col',{staticStyle:{"background-color":"#a1c8fe"},attrs:{"cols":"4"}},[_vm._v(" "+_vm._s(_vm.pk2SchoolLicences)+" ")]),_c('v-col',{staticClass:"font-weight-bold",staticStyle:{"background-color":"#4491fd"},attrs:{"cols":"8"}},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v("Remaining licenses: ")])]}}])},[_c('span',[_vm._v("These are the Lee Lee student licenses assigned to each classroom by the school administrator.")])])],1),_c('v-col',{staticStyle:{"background-color":"#4491fd"},attrs:{"cols":"4"}},[_vm._v(" "+_vm._s(_vm.pk2RemainingLicenses)+" ")])],1)],1)],1),_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.edited),expression:"edited"}],staticClass:"mb-3"},[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_vm._v(" Changes to the license distribution have been made. Would you like to save the changes? ")]),_c('v-col',{staticClass:"text-right"},[_c('v-btn',{attrs:{"color":"#72c883"},on:{"click":_vm.save}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-check")]),_vm._v(" Save ")],1)],1),_c('v-divider',{attrs:{"vertical":""}}),_c('v-col',{staticClass:"text-left"},[_c('v-btn',{attrs:{"color":"#f39ca2"},on:{"click":_vm.cancel}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-cancel")]),_vm._v(" Cancel ")],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1 mx-1 mt-5",attrs:{"headers":_vm.headers,"items":_vm.filteredItems},scopedSlots:_vm._u([{key:"header.classLicenses",fn:function(ref){
var header = ref.header;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(_vm._s(header.text))])]}}],null,true)},[_c('span',[_vm._v("These are the Lee Lee student licenses assigned to each classroom by the school administrator.")])])]}},{key:"item.class",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('v-edit-dialog',{attrs:{"return-value":item.class},on:{"update:returnValue":function($event){return _vm.$set(item, "class", $event)},"update:return-value":function($event){return _vm.$set(item, "class", $event)},"save":function($event){return _vm.saveItem(item, index, 'class')}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"type":"string","single-line":"","placeholder":"Edit"},model:{value:(item.class),callback:function ($$v) {_vm.$set(item, "class", $$v)},expression:"item.class"}})]},proxy:true}],null,true)},[_c('span',{staticClass:"bordered-field"},[_vm._v(_vm._s(item.class))])])]}},{key:"item.totalStudents",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('v-edit-dialog',{attrs:{"return-value":item.totalStudents},on:{"update:returnValue":function($event){return _vm.$set(item, "totalStudents", $event)},"update:return-value":function($event){return _vm.$set(item, "totalStudents", $event)},"save":function($event){return _vm.saveItem(item, index, 'totalStudents')}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"type":"number","single-line":"","placeholder":"Edit"},model:{value:(item.totalStudents),callback:function ($$v) {_vm.$set(item, "totalStudents", $$v)},expression:"item.totalStudents"}})]},proxy:true}],null,true)},[_c('span',{staticClass:"bordered-field"},[_vm._v(_vm._s(item.totalStudents))])])]}},{key:"item.classLicenses",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('v-edit-dialog',{attrs:{"return-value":item.classLicenses},on:{"update:returnValue":function($event){return _vm.$set(item, "classLicenses", $event)},"update:return-value":function($event){return _vm.$set(item, "classLicenses", $event)},"save":function($event){return _vm.saveItem(item, index, 'classLicenses')}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"type":"number","single-line":"","placeholder":"Edit"},model:{value:(item.classLicenses),callback:function ($$v) {_vm.$set(item, "classLicenses", $$v)},expression:"item.classLicenses"}})]},proxy:true}],null,true)},[_c('span',{staticClass:"bordered-field"},[_vm._v(_vm._s(item.classLicenses))])])]}},{key:"header.unassignedLicenses",fn:function(ref){
var header = ref.header;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(_vm._s(header.text))])]}}],null,true)},[_c('span',[_vm._v("These licenses have not yet been allocated to a specific student and are still open for assignment.")])])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"revertlicenses"},[_c('div',{staticClass:"revertlicenses_l"},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.revertUnusedLicenses(item)}}},[_c('v-icon',[_vm._v("mdi-recycle")])],1)],1),_c('div',{staticClass:"revertlicenses_r"},[_vm._v(" Revert"),_c('br'),_vm._v(" unused "),_c('br'),_vm._v(" licenses ")])])]}}])})],1)],1)],1)],1),_c('v-tab-item',[_c('v-row',[_c('v-col',[_c('v-card',{staticClass:"p-0"},[_c('v-row',[_c('v-col',{attrs:{"cols":"8"}}),_c('v-col',{attrs:{"cols":"4"}},[_c('v-row',[_c('v-col',{staticClass:"font-weight-bold",staticStyle:{"background-color":"#a1c8fe"},attrs:{"cols":"8"}},[_c('label',[_vm._v("School licenses: ")])]),_c('v-col',{staticStyle:{"background-color":"#a1c8fe"},attrs:{"cols":"4"}},[_vm._v(" "+_vm._s(_vm.g35SchoolLicences)+" ")]),_c('v-col',{staticClass:"font-weight-bold",staticStyle:{"background-color":"#4491fd"},attrs:{"cols":"8"}},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v("Remaining licenses: ")])]}}])},[_c('span',[_vm._v("These are the Lee Lee student licenses assigned to each classroom by the school administrator.")])])],1),_c('v-col',{staticStyle:{"background-color":"#4491fd"},attrs:{"cols":"4"}},[_vm._v(" "+_vm._s(_vm.g35RemainingLicenses)+" ")])],1)],1)],1),_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.edited),expression:"edited"}],staticClass:"mb-3"},[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_vm._v(" Changes to the license distribution have been made. Would you like to save the changes? ")]),_c('v-col',{staticClass:"text-right"},[_c('v-btn',{attrs:{"color":"#72c883"},on:{"click":_vm.save}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-check")]),_vm._v(" Save ")],1)],1),_c('v-divider',{attrs:{"vertical":""}}),_c('v-col',{staticClass:"text-left"},[_c('v-btn',{attrs:{"color":"#f39ca2"},on:{"click":_vm.cancel}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-cancel")]),_vm._v(" Cancel ")],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1 mx-1 mt-5",attrs:{"headers":_vm.headers,"items":_vm.filteredItems},scopedSlots:_vm._u([{key:"header.classLicenses",fn:function(ref){
var header = ref.header;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(_vm._s(header.text))])]}}],null,true)},[_c('span',[_vm._v("These are the Lee Lee student licenses assigned to each classroom by the school administrator.")])])]}},{key:"item.class",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('v-edit-dialog',{attrs:{"return-value":item.class},on:{"update:returnValue":function($event){return _vm.$set(item, "class", $event)},"update:return-value":function($event){return _vm.$set(item, "class", $event)},"save":function($event){return _vm.saveItem(item, index, 'class')}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"type":"string","single-line":"","placeholder":"Edit"},model:{value:(item.class),callback:function ($$v) {_vm.$set(item, "class", $$v)},expression:"item.class"}})]},proxy:true}],null,true)},[_c('span',{staticClass:"bordered-field"},[_vm._v(_vm._s(item.class))])])]}},{key:"item.totalStudents",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('v-edit-dialog',{attrs:{"return-value":item.totalStudents},on:{"update:returnValue":function($event){return _vm.$set(item, "totalStudents", $event)},"update:return-value":function($event){return _vm.$set(item, "totalStudents", $event)},"save":function($event){return _vm.saveItem(item, index, 'totalStudents')}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"type":"number","single-line":"","placeholder":"Edit"},model:{value:(item.totalStudents),callback:function ($$v) {_vm.$set(item, "totalStudents", $$v)},expression:"item.totalStudents"}})]},proxy:true}],null,true)},[_c('span',{staticClass:"bordered-field"},[_vm._v(_vm._s(item.totalStudents))])])]}},{key:"item.classLicenses",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('v-edit-dialog',{attrs:{"return-value":item.classLicenses},on:{"update:returnValue":function($event){return _vm.$set(item, "classLicenses", $event)},"update:return-value":function($event){return _vm.$set(item, "classLicenses", $event)},"save":function($event){return _vm.saveItem(item, index, 'classLicenses')}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"type":"number","single-line":"","placeholder":"Edit"},model:{value:(item.classLicenses),callback:function ($$v) {_vm.$set(item, "classLicenses", $$v)},expression:"item.classLicenses"}})]},proxy:true}],null,true)},[_c('span',{staticClass:"bordered-field"},[_vm._v(_vm._s(item.classLicenses))])])]}},{key:"header.unassignedLicenses",fn:function(ref){
var header = ref.header;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(_vm._s(header.text))])]}}],null,true)},[_c('span',[_vm._v("These licenses have not yet been allocated to a specific student and are still open for assignment.")])])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"revertlicenses"},[_c('div',{staticClass:"revertlicenses_l"},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.revertUnusedLicenses(item)}}},[_c('v-icon',[_vm._v("mdi-recycle")])],1)],1),_c('div',{staticClass:"revertlicenses_r"},[_vm._v(" Revert"),_c('br'),_vm._v(" unused "),_c('br'),_vm._v(" licenses ")])])]}}])})],1)],1)],1)],1)],1),_c('v-dialog',{attrs:{"hide-overlay":"","persistent":"","width":"300"},model:{value:(_vm.dialogLoad),callback:function ($$v) {_vm.dialogLoad=$$v},expression:"dialogLoad"}},[_c('v-card',{attrs:{"color":"primary","dark":""}},[_c('v-card-text',[_vm._v(" "+_vm._s(_vm.message)+" "),_c('v-progress-linear',{staticClass:"mb-0",attrs:{"indeterminate":"","color":"white"}})],1)],1)],1),_c('v-dialog',{attrs:{"persistent":"","width":"300"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.cancel($event)}},model:{value:(_vm.dialogConfirm),callback:function ($$v) {_vm.dialogConfirm=$$v},expression:"dialogConfirm"}},[_c('v-card',[_c('v-toolbar',{attrs:{"dark":"","color":"#4491fd","dense":"","flat":""}},[_c('v-toolbar-title',{staticClass:"text-body-2 font-weight-bold white--text"},[_vm._v(" Confirm ")])],1),_c('v-card-text',{staticClass:"pa-4 black--text"},[_vm._v(" "+_vm._s(_vm.message)+" ")]),_c('v-card-actions',{staticClass:"pt-3"},[_c('v-spacer'),_c('v-btn',{staticClass:"body-2 font-weight-bold",attrs:{"color":"#f39ca2","text":""},on:{"click":function($event){_vm.dialogConfirm=false}}},[_vm._v("Cancel")]),(_vm.saveRun)?_c('v-btn',{staticClass:"body-2 font-weight-bold",attrs:{"color":"#72c883","outlined":""},on:{"click":_vm.saveData}},[_vm._v("OK")]):(_vm.cancelRun)?_c('v-btn',{staticClass:"body-2 font-weight-bold",attrs:{"color":"#72c883","outlined":""},on:{"click":_vm.cancelData}},[_vm._v("Restore")]):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }