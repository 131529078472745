<template>  
  <v-container>
    <v-row>
      <v-col>
        <v-card class="pa-4">
          <v-card-title>
            <span class="text-h5">School admin - Licencias</span>
          </v-card-title>
          <!--<v-card-subtitle>
            {{admin}}
          </v-card-subtitle>-->
        </v-card>  
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-tabs v-model="tab" light @change="onTabChange">
          <v-tab>PK-2 Licenses</v-tab>
          <v-tab>3-5 Licenses</v-tab>
        </v-tabs>
      </v-col>
    </v-row>
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <v-row>
          <v-col>
            <v-card class="p-0">
              <v-row>
                <v-col cols="8"></v-col>
                <v-col cols="4">
                  <v-row>
                    <v-col cols="8" class="font-weight-bold" style="background-color: #a1c8fe;">
                      <label>School licenses: </label>
                    </v-col>
                    <v-col cols="4" style="background-color: #a1c8fe;">
                      {{pk2SchoolLicences}}
                    </v-col>
                    <v-col cols="8" class="font-weight-bold" style="background-color: #4491fd;">
                        <v-tooltip left>
                          <template v-slot:activator="{ on, attrs }">
                              <span v-bind="attrs" v-on="on">Remaining licenses: </span>
                          </template>
                          <span>These are the Lee Lee student licenses assigned to each classroom by the school administrator.</span>
                        </v-tooltip>
                    </v-col>
                    <v-col cols="4" style="background-color: #4491fd;">
                      {{pk2RemainingLicenses}}
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row class="mb-3" v-show="edited">
                <v-col cols="12" class="text-center">
                  Changes to the license distribution have been made. Would you like to save the changes? 
                </v-col>
                <v-col class="text-right">
                  <v-btn color="#72c883" @click="save">
                    <v-icon left>mdi-check</v-icon>
                    Save
                  </v-btn>
                </v-col>
                <v-divider vertical></v-divider>
                <v-col class="text-left">                
                  <v-btn color="#f39ca2" @click="cancel">
                    <v-icon left>mdi-cancel</v-icon>
                    Cancel
                  </v-btn>
                </v-col>
              </v-row>

              <v-data-table
                :headers="headers"
                :items="filteredItems"
                class="elevation-1 mx-1 mt-5"
              >
                <template v-slot:header.classLicenses="{ header }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">{{ header.text }}</span>
                    </template>
                    <span>These are the Lee Lee student licenses assigned to each classroom by the school administrator.</span>
                  </v-tooltip>
                </template>

                <template v-slot:item.class="{ item, index }">
                  <v-edit-dialog
                    :return-value.sync="item.class"
                    @save="saveItem(item, index, 'class')"                    
                  >
                    <span class="bordered-field">{{ item.class }}</span>
                    <template v-slot:input>
                      <v-text-field
                        v-model="item.class"
                        type="string"
                        single-line
                        placeholder="Edit"
                      ></v-text-field>
                    </template>
                  </v-edit-dialog>
                </template>

                <template v-slot:item.totalStudents="{ item, index }">
                  <v-edit-dialog
                    :return-value.sync="item.totalStudents"
                    @save="saveItem(item, index, 'totalStudents')"
                  >
                    <span class="bordered-field">{{ item.totalStudents }}</span>
                    <template v-slot:input>
                      <v-text-field
                        v-model="item.totalStudents"
                        type="number"
                        single-line
                        placeholder="Edit"
                      ></v-text-field>
                    </template>
                  </v-edit-dialog>
                </template>

                <template v-slot:item.classLicenses="{ item, index }">
                  <v-edit-dialog
                    :return-value.sync="item.classLicenses"
                    @save="saveItem(item, index, 'classLicenses')"
                  >
                    <span class="bordered-field">{{ item.classLicenses }}</span>
                    <template v-slot:input>
                      <v-text-field
                        v-model="item.classLicenses"
                        type="number"
                        single-line
                        placeholder="Edit"
                      ></v-text-field>
                    </template>
                  </v-edit-dialog>
                </template>

                <template v-slot:header.unassignedLicenses="{ header }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">{{ header.text }}</span>
                    </template>
                    <span>These licenses have not yet been allocated to a specific student and are still open for assignment.</span>
                  </v-tooltip>
                </template>

                <template v-slot:item.actions="{ item }">
                  <div class="revertlicenses">
                    <div class="revertlicenses_l">
                      <v-btn icon @click="revertUnusedLicenses(item)">
                        <v-icon>mdi-recycle</v-icon>
                      </v-btn>
                    </div>
                    <div class="revertlicenses_r">
                      Revert<br> 
                      unused <br>
                      licenses
                    </div>
                  </div>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </v-tab-item>      
      <v-tab-item>
        <v-row>
          <v-col>
            <v-card class="p-0">
              <v-row>
                <v-col cols="8"></v-col>
                <v-col cols="4">
                  <v-row>
                    <v-col cols="8" class="font-weight-bold" style="background-color: #a1c8fe;">
                      <label>School licenses: </label>
                    </v-col>
                    <v-col cols="4" style="background-color: #a1c8fe;">
                      {{g35SchoolLicences}}
                    </v-col>
                    <v-col cols="8" class="font-weight-bold" style="background-color: #4491fd;">
                        <v-tooltip left>
                          <template v-slot:activator="{ on, attrs }">
                              <span v-bind="attrs" v-on="on">Remaining licenses: </span>
                          </template>
                          <span>These are the Lee Lee student licenses assigned to each classroom by the school administrator.</span>
                        </v-tooltip>
                    </v-col>
                    <v-col cols="4" style="background-color: #4491fd;">
                      {{g35RemainingLicenses}}
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row class="mb-3" v-show="edited">
                <v-col cols="12" class="text-center">
                  Changes to the license distribution have been made. Would you like to save the changes? 
                </v-col>
                <v-col class="text-right">
                  <v-btn color="#72c883" @click="save">
                    <v-icon left>mdi-check</v-icon>
                    Save
                  </v-btn>
                </v-col>
                <v-divider vertical></v-divider>
                <v-col class="text-left">                
                  <v-btn color="#f39ca2" @click="cancel">
                    <v-icon left>mdi-cancel</v-icon>
                    Cancel
                  </v-btn>
                </v-col>
              </v-row>

              <v-data-table
                :headers="headers"
                :items="filteredItems"
                class="elevation-1 mx-1 mt-5"
              >
                <template v-slot:header.classLicenses="{ header }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">{{ header.text }}</span>
                    </template>
                    <span>These are the Lee Lee student licenses assigned to each classroom by the school administrator.</span>
                  </v-tooltip>
                </template>

                <template v-slot:item.class="{ item, index }">
                  <v-edit-dialog
                    :return-value.sync="item.class"
                    @save="saveItem(item, index, 'class')"                    
                  >
                    <span class="bordered-field">{{ item.class }}</span>
                    <template v-slot:input>
                      <v-text-field
                        v-model="item.class"
                        type="string"
                        single-line
                        placeholder="Edit"
                      ></v-text-field>
                    </template>
                  </v-edit-dialog>
                </template>

                <template v-slot:item.totalStudents="{ item, index }">
                  <v-edit-dialog
                    :return-value.sync="item.totalStudents"
                    @save="saveItem(item, index, 'totalStudents')"
                  >
                    <span class="bordered-field">{{ item.totalStudents }}</span>
                    <template v-slot:input>
                      <v-text-field
                        v-model="item.totalStudents"
                        type="number"
                        single-line
                        placeholder="Edit"
                      ></v-text-field>
                    </template>
                  </v-edit-dialog>
                </template>

                <template v-slot:item.classLicenses="{ item, index }">
                  <v-edit-dialog
                    :return-value.sync="item.classLicenses"
                    @save="saveItem(item, index, 'classLicenses')"
                  >
                    <span class="bordered-field">{{ item.classLicenses }}</span>
                    <template v-slot:input>
                      <v-text-field
                        v-model="item.classLicenses"
                        type="number"
                        single-line
                        placeholder="Edit"
                      ></v-text-field>
                    </template>
                  </v-edit-dialog>
                </template>

                <template v-slot:header.unassignedLicenses="{ header }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">{{ header.text }}</span>
                    </template>
                    <span>These licenses have not yet been allocated to a specific student and are still open for assignment.</span>
                  </v-tooltip>
                </template>

                <template v-slot:item.actions="{ item }">
                  <div class="revertlicenses">
                    <div class="revertlicenses_l">
                      <v-btn icon @click="revertUnusedLicenses(item)">
                        <v-icon>mdi-recycle</v-icon>
                      </v-btn>
                    </div>
                    <div class="revertlicenses_r">
                      Revert<br> 
                      unused <br>
                      licenses
                    </div>
                  </div>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </v-tab-item>
    </v-tabs-items>
    <v-dialog
      v-model="dialogLoad"
      hide-overlay
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text>
          {{message}}
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogConfirm"
      persistent
      width="300"
      @keydown.esc="cancel"
    >
      <v-card>
        <v-toolbar dark color="#4491fd" dense flat>
          <v-toolbar-title class="text-body-2 font-weight-bold white--text">
            Confirm
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text          
          class="pa-4 black--text"         
        >
        {{message}}
        </v-card-text>
        <v-card-actions class="pt-3">
          <v-spacer></v-spacer>
          <v-btn            
            color="#f39ca2"
            text
            class="body-2 font-weight-bold"
            @click="dialogConfirm=false"
            >Cancel</v-btn
          >
          <v-btn
            v-if="saveRun"
            color="#72c883"
            class="body-2 font-weight-bold"
            outlined
            @click="saveData"
            >OK</v-btn
          >
          <v-btn
            v-else-if="cancelRun"
            color="#72c883"
            class="body-2 font-weight-bold"
            outlined
            @click="cancelData"
            >Restore</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import { auth, db } from "../../../plugins/firebase";
import firebase from "firebase";
export default {
  data() {
    return {
      tab: 0,
      admin: '',
      pk2SchoolLicences: 0,
      g35SchoolLicences: 0,
      pk2RemainingLicenses: 0,
      g35RemainingLicenses: 0,
      reference: '',
      headers: [
        { text: 'Teacher', value: 'teacher' },
        { text: 'Class', value: 'class' },
        { text: 'Total Students', value: 'totalStudents' },
        { text: 'Class Licenses', value: 'classLicenses' },
        { text: 'Assigned Licenses', value: 'assignedLicenses' },
        { text: 'Unassigned Licenses', value: 'unassignedLicenses' },
        { text: 'Acciones', value: 'actions', sortable: false }
      ],
      items: [],
      originalData: [],
      gradeList: ['Pre-School', 'Kindergarten', '1st Grade', '2nd Grade', '3rd Grade', '4th Grade', '5th Grade', '6th Grade'],
      saveRun: false,
      cancelRun: false,
      dialogLoad: false,
      dialogConfirm: false,
      edited: false,
      message: ''
    };
  },
  created() {},
  methods: {
    async getData() {
      this.dialogLoad = true;
      this.message="Loading data...";
      const id = localStorage.getItem('uid');

      // Obtener datos del administrador
      let info = await db.collection('SchoolAdmin').doc(id).get();
      this.admin = info.data().Name + ' ' + info.data().LastName;
      this.reference = info.data().Reference.path;

      // Obtener datos de la escuela
      const schoolData = await db.doc(this.reference).get();
      this.pk2SchoolLicences = schoolData.data().L1SchoolLicences;
      this.pk2RemainingLicenses = schoolData.data().L1RemainingLicenses;
      this.g35SchoolLicences = schoolData.data().L2SchoolLicences;
      this.g35RemainingLicenses = schoolData.data().L2RemainingLicenses;

      // Obtener datos de los profesores
      const teacherIds = Object.keys(schoolData.data().SchoolTeachers);
      const teacherPromises = teacherIds.map(idt => db.collection('SchoolTeacher').doc(idt).get());

      // Esperar a que todas las promesas se resuelvan en paralelo
      const teacherDocs = await Promise.all(teacherPromises);

      const items = teacherDocs.flatMap(TempTeacherData => {
        const classrooms = TempTeacherData.data().Classrooms;
        const nameTeacher = TempTeacherData.data().Name + ' ' + TempTeacherData.data().LastName;
        const classNames = Object.keys(classrooms);

        // Crear datos de las clases
        return classNames.map(ClassroomSelected => {
          const classroomData = classrooms[ClassroomSelected];
          return {
            uid: TempTeacherData.id,
            teacher: nameTeacher,
            classUid: ClassroomSelected,
            class: classroomData.Name,
            totalStudents: classroomData.hasOwnProperty('TotalStudents') ? classroomData.TotalStudents : 0,
            classLicenses: classroomData.hasOwnProperty('ClassLicenses') ? classroomData.ClassLicenses : 0,
            assignedLicenses: classroomData.hasOwnProperty('AssignedLicenses') ? classroomData.AssignedLicenses : 0,
            unassignedLicenses: (classroomData.ClassLicenses - classroomData.AssignedLicenses) || 0,
            grade: classroomData.hasOwnProperty('Grade') ? classroomData.Grade : ''
          };
        });
      });

      this.items = items;
      this.originalData = JSON.parse(JSON.stringify(items));
      this.dialogLoad = false;
    },
    saveItem(item, index, field) { 
      this.edited=true;
      this.filteredItems[index].totalStudents = parseInt(item.totalStudents);
      this.filteredItems[index].classLicenses = parseInt(item.classLicenses);
      
      console.log('Item guardado:', this.filteredItems[index], index);
      if (field=='classLicenses'){
        item.unassignedLicenses=this.filteredItems[index].classLicenses-this.filteredItems[index].assignedLicenses;
      }
    },
    save(){
      this.dialogConfirm=true;
      this.saveRun=true;
      this.message='Do you want to save the changes made?';
    },
    cancel(){
      this.dialogConfirm=true;
      this.cancelRun=true;
      this.message='Do you want to CANCEL the changes made?';
    },
    async saveData(){
      this.dialogLoad = true;
      this.message="Saving data...";
      console.log("ITEMS---->");
      console.log(this.items);
      for (const [key, item] of this.items.entries()){
        /*console.log(item);
        console.log("ORIGINAL VALUES-----> "+key+" "+item.teacher);
        console.log(this.originalData[key]);*/
        if (JSON.stringify(item) === JSON.stringify(this.originalData[key])) continue;
        console.log(item);
        console.log("------------------------------------------------------------------");
        console.log(this.originalData[key]);
        try {
          await db.collection('SchoolTeacher').doc(item.uid).set({
            Classrooms: {
              [item.classUid]: {
                Name: item.class,
                TotalStudents: parseInt(item.totalStudents),
                ClassLicenses: parseInt(item.classLicenses),
                AssignedLicenses: parseInt(item.assignedLicenses)
              }
            }
          }, { merge: true });
          item.classLicenses=parseInt(item.classLicenses);
          this.originalData[key] = JSON.parse(JSON.stringify(item));
          this.edited=false;
        }catch(error) {
          console.log(error);
        }
      }console.log("**************************************"); console.log(this.originalData);
      this.dialogLoad = false;
      this.dialogConfirm=false;
      this.saveRun=false;
    },
    cancelData(){
      this.items = JSON.parse(JSON.stringify(this.originalData));
      this.dialogConfirm=false;
      this.cancelRun=false;
      this.edited=false;
    },
    async revertUnusedLicenses(item){
      if (item.unassignedLicenses==0) return;
      this.edited=true;
      let unassignedLicenses=item.unassignedLicenses;
      let unused=item.classLicenses-item.unassignedLicenses;
      item.classLicenses=unused;
      item.unassignedLicenses=unused-item.assignedLicenses;
      let remainingLicenses=''; 
      if (this.tab === 0) {
        remainingLicenses='L1RemainingLicenses';
        this.pk2RemainingLicenses+=unassignedLicenses;
      } else if (this.tab === 1) {
        remainingLicenses='L2RemainingLicenses';
        this.g35RemainingLicenses+=unassignedLicenses;
      }  
      await db.doc(this.reference).update({[`${remainingLicenses}`]: firebase.firestore.FieldValue.increment(unassignedLicenses)});
      
      //this.saveData();
    },
    onTabChange(newTab) {
      console.log("CAMBIAMOS DE TAB-> "+newTab+" "+this.tab);
      this.cancelData();
      this.edited=false;
    }
  },
  mounted() {     
    this.getData();
  },
  computed: {
    filteredItems() {      
      if (this.tab === 0) {
        return this.items.filter(item => this.gradeList.slice(0, 4).includes(item.grade));
      } else if (this.tab === 1) {
        return this.items.filter(item => this.gradeList.includes(item.grade, 4));
      }
      return this.items;
    }
  },
};
</script>
<style>
.v-tab--active {
    color: white !important;
    background-color: #4491fd;
    border-radius: 1rem 1rem 0 0;    
}
.theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active), .theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) > .v-icon, .theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) > .v-btn, .theme--light.v-tabs > .v-tabs-bar .v-tab--disabled {
    color: white !important;
    background-color: #a1c8fe;
    border-radius: 1rem 1rem 0 0;    
}

.theme--light.v-tabs-items {    
    border: 0.3rem solid #4491fd;
    border-radius: 0 1rem 1rem 1rem;
}
.v-data-table-header{
  background-color: #a1c8fe;
  font-weight: bold;
}
.revertlicenses{
  display: block;
  background-color: #EEEEEE;
  border: 1px solid #7F7F7F;
  padding: 1%;
  align-items: center;  
  justify-content: center;
  overflow: hidden;
}
.revertlicenses_r{
  display: block;
  float: right;
  font-size: 0.7rem;
  text-align: right;
  line-height: 1.2em;
}
.revertlicenses_l{
  display: flex;
  float: left;  
  align-items: center;  
  justify-content: center;
}
.v-small-dialog__activator__content {
    display: block !important;
}
.bordered-field {
  display: block;
  width: 100%;
  border: 1px solid #ccc;
  padding: 4px; 
  border-radius: 4px;  
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
}
.centerXY {
  display: flex;
  align-items: center;
  justify-content: center; 
}
</style>